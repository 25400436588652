// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-find-therapist-waitlist-index-js": () => import("./../../src/pages/find-therapist-waitlist/index.js" /* webpackChunkName: "component---src-pages-find-therapist-waitlist-index-js" */),
  "component---src-pages-for-providers-index-js": () => import("./../../src/pages/for-providers/index.js" /* webpackChunkName: "component---src-pages-for-providers-index-js" */),
  "component---src-pages-for-providers-insurance-index-js": () => import("./../../src/pages/for-providers/insurance/index.js" /* webpackChunkName: "component---src-pages-for-providers-insurance-index-js" */),
  "component---src-pages-founding-member-massachusetts-index-js": () => import("./../../src/pages/founding-member-massachusetts/index.js" /* webpackChunkName: "component---src-pages-founding-member-massachusetts-index-js" */),
  "component---src-pages-get-matched-js": () => import("./../../src/pages/get-matched.js" /* webpackChunkName: "component---src-pages-get-matched-js" */),
  "component---src-pages-how-it-works-index-js": () => import("./../../src/pages/how-it-works/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-index-js": () => import("./../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-our-mission-js": () => import("./../../src/pages/our-mission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-refer-a-provider-index-js": () => import("./../../src/pages/refer-a-provider/index.js" /* webpackChunkName: "component---src-pages-refer-a-provider-index-js" */),
  "component---src-pages-self-studies-index-js": () => import("./../../src/pages/self-studies/index.js" /* webpackChunkName: "component---src-pages-self-studies-index-js" */)
}

